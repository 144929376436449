// VacancyClossed.jsx
import React from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

import ClosedVacancyData from './ClosedVacancyData';
import VacancyCard from './VacancyCard';

const ClosedVacancy = ({ closedVacancyOpen, toggleClosedVacancy }) => {
  return (
    <div className="section1">
      <div className="section-header1" onClick={toggleClosedVacancy}>
        Expression of Interest - Closed {closedVacancyOpen ? <FaMinus /> : <FaPlus />}
      </div>
      {closedVacancyOpen && (
        <div className="section-content1">
          {ClosedVacancyData.map((vacancy) => (
            <VacancyCard
              key={vacancy.id}
              title={vacancy.title}
              subheading={vacancy.subheading}
              description={vacancy.description}
              applicationLink={vacancy.applicationLink}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ClosedVacancy;
