import React from 'react';
import './AboutUs.css'; // Create a CSS file for styling
import aboutUsImage from '../../Assets/images/road-int.jpg';

const AboutUs = () => {
  return (
    <div className="about-us-section">
      <div className="about-us-card">
        <div className="about-us-text">
          <h2>About ROAD</h2>
          <p>
            <span className="initials">R</span>ights <span className="initials">O</span>rganization for <span className="initials">A</span>dvocacy and <span className="initials">D</span>evelopment <span className="initials">(R.O.A.D)</span>  is a non-governmental, non-profit organisation founded and registered in Kenya and Somalia in 2009 and 2012 respectively. The organisation is registered in Kenya under the NGO Coordination Act. The organization was formed to respond effectively to humanitarian challenges and contribute towards government’s/country’s Short and long-term development priorities through participatory and sustainable approaches.
          </p>
          <p>Through social and economic empowerment and inclusion approach, ROAD aims to improve access to basic social services, support and expand economic opportunities, and enhance environmental management of vulnerable communities including refugees and internally displaced persons.
          </p>
          <a href="/about-us" className="read-more-button">Read More</a>
        </div>
        <div className="about-us-image">
          <img src={aboutUsImage} alt="About Us" /> 
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
