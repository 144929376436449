import React from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

import ClosedTendersData from './closedTenderData';
import TenderCard from './TenderCard';

const ClosedTenders = ({ closedTendersOpen, toggleClosedTenders }) => {
  return (
    <div className="section">
      <div className="section-header" onClick={toggleClosedTenders}>
        Closed Tenders {closedTendersOpen ? <FaMinus /> : <FaPlus />}
      </div>
      {closedTendersOpen && (
        <div className="section-content">
          {ClosedTendersData.map((tender) => (
            <TenderCard
              key={tender.id}
              title={tender.title}
              subheading={tender.subheading}
              description={tender.description}
              torLink={tender.torLink}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ClosedTenders;
