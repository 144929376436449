import React from 'react';
import './AboutPage.css';
import ProjectsPage from '../ProjectsPost/ProjectSlider';
import CoreValuesSection from '../../CoreValuesSection/CoreValuesSection';

const AboutPage = () => {
  return (
    <>
      <div className="about-container1">
        <div className="about-card">
          <h2>Who we are</h2>
          <p>
            Our organization is a non-partisan, non-racial, non-sectarian, and non-profit organization founded on the spirit of charity. We also undertake community and institutional training and capacity building on best practices in community empowerment through civic education and local resources mobilization; integrated project design and planning; implementation; good governance and resource management practices; monitoring and evaluation; transparency and accountability for sustainable development projects and programs impacts.
          </p>
        </div>
        <div className="about-card">
          <h2>Mission Statement</h2>
          <p>
            Our Mission is to build the capacity of our grassroots beneficiaries and develop partner institutions in Disaster reduction, Sustainable development, Economic empowerment, WASH, Health, Livelihoods, and Food security, Conflict resolution, and peace building by mitigating impacts of climate change and promoting transformational development, gender empowerment, needs-based training, Project management, Monitoring, Evaluation, and Sustainable impacts.
          </p>
        </div>
        <div className="about-card">
          <h2>Principles</h2>
          <p>
            ROAD’s principles and associates are highly qualified professionals of high integrity who view assignments objectively and who have had both extensive and intensive international relief and development experience spanning over 25 years in policy research and development, relief, rehabilitation, and development at community levels.
          </p>
        </div>
        <div className="about-card">
          <h2>Our Vision</h2>
          <p>
            To be a leading Disaster Risk Reduction, Development, Livelihoods, Food security, WASH Health, and Peace building Agency in Somalia, Northern Kenya, and the Greater Horn of Africa region.
          </p>
        </div>
      </div>
      <CoreValuesSection />
      <ProjectsPage />
    </>
  );
};

export default AboutPage;
