// VacancyOpen.jsx
import React from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const OpenVacancy = ({ openVacancyOpen, toggleOpenVacancy }) => {
  return (
    <div className="section1">
      <div className="section-header1" onClick={toggleOpenVacancy }>
        Expression of Interest - Open {openVacancyOpen ? <FaMinus /> : <FaPlus />}
      </div>
      {openVacancyOpen ? (
        <div className="section-content1">
          No vacancies available at the moment. Please check back later.
        </div>
      ) : null}
    </div>
  );
};

export default OpenVacancy;
