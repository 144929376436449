import React, { useState } from 'react';
import { sidebarData } from '../sidebar/Sidebardata';
import './Sidebar.css';

const Sidebar = ({ isOpen, onCloseSidebar }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleSubmenuToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="close-sidebar" onClick={onCloseSidebar}>
        &times;
      </div>
      <ul className="menu2">
        {sidebarData.map((menuItem2, index) => (
          <li key={index} className="menu-item2">
            <a href={menuItem2.url2}>{menuItem2.title2}</a>
            {menuItem2.submenuItems2.length > 0 && (
              <>
                <button
                  className={`submenu-toggle ${activeIndex === index ? 'active' : ''}`}
                  onClick={() => handleSubmenuToggle(index)}
                >
                  {activeIndex === index ? '-' : '+'}
                </button>
                <ul className={`submenu2 ${activeIndex === index ? 'open' : ''}`}>
                  {menuItem2.submenuItems2.map((submenuItem2, subIndex) => (
                    <li key={subIndex} className="submenu-item">
                      <a href={submenuItem2.url2}>{submenuItem2.title2}</a>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
