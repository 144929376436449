import React from 'react';

import HeroSection from '../heroSection/hero';
import AboutUs from '../about/About';
import ThematicAreas from '../Thematicarea/ThematicArea';
import HelpUs from '../HelpUs/HelpUs';
import PartnersSection from '../Partners/partnerSection';
import ScrollToTopButton from '../ScrollToTopButton';
import ProjectSlider from './ProjectsPost/ProjectSlider';

const Home = () => {

    // const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    // const handleToggleSidebar = () => {
    //   setIsSidebarOpen(!isSidebarOpen);
    // };

    return (
        <>
            {/* <ContactBar />
            <Navbar onToggleSidebar={handleToggleSidebar} />
            {isSidebarOpen && <Sidebar />} */}
        
        <div className="content-container">
            <HeroSection />
            <AboutUs />
            <ThematicAreas />
            <ProjectSlider />
            <HelpUs />
            <PartnersSection />
            <ScrollToTopButton />
        
        </div>
        </>
    );
}

export default Home;
