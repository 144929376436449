// vacancyData.js
const closedVacancyData = [

  {
    id: 1,
    title: (
      <span>
        Call for Application <span style={{ color: 'red' }}>- Closed 5th October, 2023</span>
      </span>
    ),
    subheading: "Short-Term Financial Consultant Wanted",
    description: (
      <div>
        To enhance ROAD's financial management, controls, systems, and procedures, we seek a short-term financial consultant available for immediate engagement. The revision process should be concluded by mid-November 2023.
        <br />
        <span style={{ fontWeight: 'bold' }}>Responsibilities:</span>
        <ul>
          <li>Provide a detailed assessment report on ROAD’s financial procedures, tools, and IT supporting system.</li>
          <li>Prepare a training report on QuickBooks customization/internalization to fit the organization, from data migration to report writing and export.</li>
          <li>Draft a cost-sharing policy.</li>
          <li>Produce an assessment report on the use of ERP upon installation completion.</li>
          <li>Review ROAD's financial manuals.</li>
          <li>Train finance officers and staff on QuickBooks and its customization for non-profit organizations, including the export of customized reports.</li>
          <li>Evaluate current ROAD financial procedures, tools, and IT support systems.</li>
          <li>Develop a cost-sharing policy, plan, and procedures, and if absent, draft a finance manual and guideline.</li>
        </ul>
        <span style={{ fontWeight: 'bold' }}>Specific Requirements:</span>
        <ul>
          <li>An advanced relevant degree, preferably a bachelor’s in Financial Management, Economics, or a related discipline, coupled with a solid understanding of financial management in NGOs or non-profit entities.</li>
          <li>At least 10 years of pertinent professional work experience, with 5 in management of development partners such as USAID, DANIDA, EU-funded projects, project financial administration, and budgeting, including leadership of multi-disciplinary expert teams.</li>
          <li>Familiarity with existing financial software/systems.</li>
          <li>Knowledge of the Kenyan financial sector, particularly in agricultural finance and climate/green finance, is an advantage.</li>
          <li>Deep understanding of Kenya’s multi-level government systems (both National and County Governments) and their governance and administrative modalities.</li>
          <li>Relevant ICT skills.</li>
          <li>Proficiency in written and spoken English; command of Kiswahili is desirable.</li>
        </ul>
        Interested candidates should provide ROAD with:
        <ul>
          <li>A technical proposal outlining the methods and procedures for the consultancy.</li>
          <li>A financial proposal.</li>
          <li>An updated CV in English.</li>
        </ul>
        Deadline for applications: October 5th, 2023.
      </div>
    ),
    applicationLink: "https://road-international.org/media/Closed/FINANCIAL%20CONSULTANT-%20Final%20.pdf",
  },

  
  {
    id: 2,
    title: (
      <span>
        Call for Application <span style={{ color: 'red' }}>- Closed 19th May, 2023</span>
      </span>
    ),
    subheading: "Expertise in Fodder Production in Dryland and Irrigation Farming Required",
    description: (
      <div>
        We are actively seeking a consultant with extensive experience and knowledge in fodder production, encompassing both dryland and irrigation farming.
        <br />
        <span style={{ fontWeight: 'bold' }}>Specific Requirements:</span>
        <ul>
          <li>Over ten (10) years of professional experience in agricultural practice and management.</li>
          <li>Strong technical capacity to execute the consultancy.</li>
          <li>Must provide proof of compliance with legal requirements and relevant professional registration/membership.</li>
          <li>Excellent data analysis and report writing capabilities.</li>
          <li>Strong communication and facilitation skills.</li>
          <li>Fluency in English.</li>
        </ul>
        The deadline for interested candidates to submit their proposals is{' '}
        <span style={{ color: '#000' }}>19th May, 2023</span>.
      </div>
    ),
    applicationLink: "https://road-international.org/media/Closed/TOR%20Agricultural%20Consultant.pdf",
  },
  
    // Add more vacancy objects as needed
  ];
  
  export default closedVacancyData;
  