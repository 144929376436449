import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './hero.css';
import slider1 from '../../Assets/images/food-and-medical-aid.jpg';
import slider2 from '../../Assets/images/slider3.jpg';
import slider3 from '../../Assets/images/slider2.jpg';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  // Custom arrow components
  const PrevArrow = (props) => (
    <div {...props} className="slick-arrow slick-prev">
    </div>
  );

  const NextArrow = (props) => (
    <div {...props} className="slick-arrow slick-next">
    </div>
  );

  const sliderSettings = {
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1500, // Adjust speed for smoother animation
    fade: true,
    cssEase: 'ease-out', // Use ease-out for smoother transition
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          speed: 1000, // Adjust speed for smoother animation
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          speed: 800, // Adjust speed for smoother animation
        },
      },
    ],
  };

  return (
    <div className="hero-section">
      <Slider {...sliderSettings}>
        {[
          {
            image: slider1,
            title: 'Welcome to',
            subTitle: 'Right Organization For Advocacy & Development',
            description: `We uphold the pillars of transparency and accountability as fundamental to the success of our initiatives. We are committed to maintaining the highest standards of openness and responsibility in our interactions with donors, government authorities, communities, and development partners.`,
            linkTo: '/about-us',
            buttonText: 'About Us',
          },
          {
            image: slider2,
            title: "",
            subTitle: 'We are Founded On The Spirit of Charity',
            description: `Our ethos revolves around compassion for the communities and individuals we serve. We recognize and empathize with the unique challenges they face, and our approach is rooted in providing support with genuine care and kindness.`,
            linkTo: '/thematic-areas',
            buttonText: 'Read More',
          },
          {
            image: slider3,
            title: "It's Time for Change",
            subTitle: 'Support us in Our Course of Helping Others',
            description: ``,
            linkTo: '#', // Change to your donation link
            buttonText: 'Donate',
          },
        ].map((item, index) => (
          <div key={index} className="slider-image">
            <img src={item.image} alt={`Slider ${index + 1}`} className="image-slide" />
            <div className="hero-text">
              <h1>{item.title}</h1>
              <p>
                <span className="green-text">{item.subTitle}</span>
              </p>
              <p className='heroDescription'>{item.description}</p>
              <Link to={item.linkTo} className="hero-button1">
                {item.buttonText}
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HeroSection;
