import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProjectPost.css';

import projectImage1 from '../../../Assets/images/Picture-1.jpg';
import projectImage2 from '../../../Assets/images/website-Road2.png';
import projectImage3 from '../../../Assets/images/IMG-20170718-WA0007.jpg';
import projectImage4 from '../../../Assets/images/newImages/Deriswanag.jpeg';
import projectImage5 from '../../../Assets/images/newImages/RLAjointMonitoringField.jpeg';
import projectImage6 from '../../../Assets/images/newImages/SIDA2.jpeg';

const ProjectSlider = () => {
  const PrevArrow = (props) => (
    <div {...props} className="slick-arrow slick-prev">
    </div>
  );

  const NextArrow = (props) => (
    <div {...props} className="slick-arrow slick-next">
    </div>
  );

  const sliderSettings = {
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3, // Show three slides
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // for screens with max width 1024px and above
        settings: {
          slidesToShow: 3, // Show three slides
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767, // for screens with max width 767px
        settings: {
          slidesToShow: 1, // Show one slide
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  return (
    <div className="projectsPost-section">
      <h1 className="animated-title">Projects</h1>
      <h2>Ongoing and Completed Projects</h2>

      <div className="projectPost-slider">
        <Slider {...sliderSettings}>

          <div>
            <div className="projectPost-card">
              <img src={projectImage6} alt="Project 1" />
              <h3>Supporting Initiatives in Rehabilitation and Reintegration (SIRR)" Project</h3>
              <p>
              Supporting Initiatives in Rehabilitation and Reintegration (SIRR)" Project (Ongoing till 2026).Funded by GCERF, this project supports initiatives focused on rehabilitating and reintegrating individuals affected by violent extremism in Garissa County...
              </p>
              <a href="/sirr-project" className="view-details-button">View More Details</a>
            </div>
          </div>
          <div>
            <div className="projectPost-card">
              <img src={projectImage4} alt="Project 1" />
              <h3>Promoting Community Resilience to Conflicts and Violent Extremism (2023-2024)</h3>
              <p>
              This initiative aims to foster community resilience to conflicts and violent extremism in Garissa County. The objective is to build stronger communities capable of withstanding ...
              </p>
              <a href="/community-resilience" className="view-details-button">View More Details</a>
            </div>
          </div>
          <div>
            <div className="projectPost-card">
              <img src={projectImage5} alt="Project 1" />
              <h3>Resilience Learning and Adaptation Project in Garissa County.</h3>
              <p>
              The Resilience Learning and Adaptation Project, conducted in Garissa County, operates from 2022 to 2024. Funded by USAID, its primary objective is to coordinate efforts with the County Government ...
              </p>
              <a href="/resilience-adaptation-project-garissa-county" className="view-details-button">View More Details</a>
            </div>
          </div>
          <div>
            <div className="projectPost-card">
              <img src={projectImage1} alt="Project 1" />
              <h3>Livelihood Project</h3>
              <p>
              ROAD with support from IHH Humanitarian Relief Foundation did urgent food and medical AID to the refugee population of Daadab and the host community...
              </p>
              <a href="/livelihood" className="view-details-button">View More Details</a>
            </div>
          </div>

          <div>
            <div className="projectPost-card">
              <img src={projectImage2} alt="Project 2" />
              <h3>Livestock Market Systems – Strengthening Communities Capacities for Resilience and Growth (LMS)</h3>
              <p>
                Project Title: Livestock Market Systems – Strengthening Communities Capacities for Resilience and Growth (LMS). Project Period: March 2021 - June 2022...
              </p>
              <a href="/livestock-market-systems-strengthening-communities-capacities-for-resilience-and-growth-lms" className="view-details-button">View More Details</a>
            </div>
          </div>

          <div>
            <div className="projectPost-card">
              <img src={projectImage3} alt="Project 3" />
              <h3>IOM</h3>
              <p>
                IOM in Bakol Elbadre district. Renovation of borehole and water tracking.
              </p>
              <a href="/
              /iom" className="view-details-button">View More Details</a>
            </div>
          </div>

          <div>
            <div className="projectPost-card">
              <img src={projectImage1} alt="Project 1" />
              <h3>Livelihood Project</h3>
              <p>
              ROAD with support from IHH Humanitarian Relief Foundation did urgent food and medical AID to the refugee population of Daadab and the host community...
              </p>
              <a href="/livelihood" className="view-details-button">View More Details</a>
            </div>
          </div>

          <div>
            <div className="projectPost-card">
              <img src={projectImage2} alt="Project 2" />
              <h3>Livestock Market Systems – Strengthening Communities Capacities for Resilience and Growth (LMS)</h3>
              <p>
                Project Title: Livestock Market Systems – Strengthening Communities Capacities for Resilience and Growth (LMS). Project Period: March 2021 - June 2022...
              </p>
              <a href="/livestock-market-systems-strengthening-communities-capacities-for-resilience-and-growth-lms" className="view-details-button">View More Details</a>
            </div>
          </div>

          <div>
            <div className="projectPost-card">
              <img src={projectImage3} alt="Project 3" />
              <h3>IOM</h3>
              <p>
                IOM in Bakol Elbadre district. Renovation of borehole and water tracking.
              </p>
              <a href="/
              /iom" className="view-details-button">View More Details</a>
            </div>
          </div>

        </Slider>
      </div>
    </div>
  );
};

export default ProjectSlider;

