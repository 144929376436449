import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css'; // Import CSS for styling

const NotFoundPage = () => {
  return (
    <div className="notFoundPage">
      <h1>404 - Not Found</h1>
      {/* <h2>This is somewhat embarrassing, isn't it?</h2> */}
      <p>It looks like the page you are looking for does not exist at this location.</p>
      <Link to="/" className="homeLink">
        Click here to go back home
      </Link>
    </div>
  );
};

export default NotFoundPage;
