const closedTenderData = [
  {
    id: 1,
    title: (
      <span>
        REQUEST FOR QUOTATION (RFQ) <span style={{ color: 'red' }}>- Closed 12th September, 2023.</span>
      </span>
    ),
    subheading: "Tender for the Procurement of Livestock assets (Goats)",
    description: (
      <div>
        Rights Organization for Advocacy and Development (ROAD) invites interested reliable Suppliers with sound capacity to submit their offers for the procurement of Livestock asset (Goats) to be delivered and distributed to selected household beneficiaries in Dadaab and Balambala sub-county selected villages (villages to be decided). 
        
        
        <br /> 
        <br/>
        <span style={{ fontWeight: 'bold' }}>Quotations may be submitted on or before 12th September, 2023, at 5:00 p.m. EAT Local Time. </span>
      </div>
    ),
    torLink: "https://road-international.org/media/Closed/RFQ-Livestock Restocking .pdf",
  },

  {
    id: 2,
    title: (
      <span>
        REQUEST FOR QUOTATION (RFQ) <br />
Re-advertisement <span style={{ color: 'red' }}>- Closed June 06, 2022.</span>
      </span>
    ),
    subheading: ( 
      <div> Lot 1: ROAD/LMS/2022/1: Proposed Construction of elevated steel water tank 40,000L on a 12-meter-high steel 
      tower in Waberi East location Waberi ward Garissa County. </div>),
    description: (
      <div>
        Rights Organization for Advocacy and Development (ROAD) invites interested contractors with sound capacity 
and relevant experience in similar works to submit their offers against the construction of elevated steel water 
tank (12-meter high with capacity of 40,000 litres) in Waberi East location Waberi ward in Garissa County.


<br />
<br/> 
<span style={{ fontWeight: 'bold' }}>Interested contractors are to submit their bids clearly marked.

<br/> 
<br/>
Quotations may be submitted on or before June 06, 2022, at 9:00 a.m. EAT Local Time.</span>
      </div>
    ),
    torLink: "https://road-international.org/media/Closed/Waberi-WASH-grant-Lot-1-call-for-proposal.zip",
  },
  
  {
    id: 3,
    title: (
      <span>
        REQUEST FOR QUOTATION (RFQ)  <span style={{ color: 'red' }}>- Closed May 26, 2022.</span>
      </span>
           ),
    subheading: ( 
      <div> Lot 2: ROAD/LMS/2022/2: Proposed De-silting of water pans and associated works at Dujis in Balambala ward, 
      Balambala sub-county in Garissa County.  </div>),
    description: (
      <div>
        Rights Organization for Advocacy and Development (ROAD) invites interested contractors with sound capacity 
        and relevant experience in similar works to submit their offers against the proposed de-silting of water pan and 
        associated works at Dujis in Balambala ward, Balambala sub-county in Garissa County. Interested contractors are 
        to submit their bids clearly marked.


        <br />
        <br/> 
        <span style={{ fontWeight: 'bold' }}>
        Quotations may be submitted on or before May 26, 2022, at 5:00 p.m.</span>
      </div>
        ),
        torLink: "https://road-international.org/media/Closed/Balambala-WASH-grant-Lot-2-call-for-proposal.zip",
  },

  {
    id: 4,
    title: (
      <span>
        REQUEST FOR QUOTATION (RFQ)  <span style={{ color: 'red' }}>- Closed May 26, 2022.</span>
      </span>
           ),
    subheading: ( 
      <div> Lot 1: ROAD/LMS/2022/1: Proposed Construction of elevated steel water tank 40,000L on a 12-meter-high steel 
      tower in Waberi East location Waberi ward Garissa County. </div>),
    description: (
      <div>
        Rights Organization for Advocacy and Development (ROAD) invites interested contractors with sound capacity 
        and relevant experience in similar works to submit their offers against the construction of elevated steel water 
        tank (12-meter high with capacity of 40,000 litres) in Waberi East location Waberi ward in Garissa County.
        Interested contractors are to submit their bids clearly marked.


        <br />
        <br/> 
        <span style={{ fontWeight: 'bold' }}>
        Quotations may be submitted on or before May 26, 2022, at 5:00 p.m.</span>
      </div>
        ),
        torLink: "https://road-international.org/media/Closed/Waberi-Readvertisement-of-Lot-1.zip",
  },

    // Add more tender objects as needed
  ];
  
  export default closedTenderData;
  