import React, { Component } from 'react';
import ClosedVacancy from './ClosedVacancy';
import OpenVacancy from './OpenVacancy';
import './Vacancy.css'; 

class Vacancy extends Component {
    constructor(props) {
        super(props);
        this.state = {
          openVacancyOpen: true,
          closedVacancyOpen: false,
        };
      }
    
      toggleOpenVacancy = () => {
        this.setState({
          openVacancyOpen: !this.state.openVacancyOpen,
          closedVacancyOpen: false,
        });
      };
    
      toggleClosedVacancy = () => {
        this.setState({
          openVacancyOpen: false,
          closedVacancyOpen: !this.state.closedVacancyOpen,
        });
  };

  render() {
    return (
      <div className="vacancy-page1">
        <OpenVacancy openVacancyOpen={this.state.openVacancyOpen} toggleOpenVacancy={this.toggleOpenVacancy} />
        <ClosedVacancy closedVacancyOpen={this.state.closedVacancyOpen} toggleClosedVacancy={this.toggleClosedVacancy} />
      </div>
    );
  }
}

export default Vacancy;
