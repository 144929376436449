// Preloader.js

import React, { useState, useEffect } from 'react';
import './Preloader.css'; // Import CSS styles for the preloader
import companyLogo from './../../Assets/logos/main-logo.jpg'; // Import the company logo image

const Preloader = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      // Simulate loading progress increase
      setLoadingProgress(prevProgress => {
        const newProgress = prevProgress + 10;
        return newProgress >= 100 ? 100 : newProgress; // Ensure progress does not exceed 100
      });
    }, 100); // Simulating loading progress update every 0.1 seconds

    // Cleanup function to clear the timer
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="preloader-container">
      <div className="logo">
        <img src={companyLogo} alt="Company Logo" />
      </div>
      <div className="loading-bar">
        <div className="progress" style={{ width: `${loadingProgress}%` }}></div>
      </div>
    </div>
  );
};

export default Preloader;
