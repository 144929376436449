import React from 'react';
import './Projects.css'; // Import your custom CSS

// Sample images for project cards
import projectImage1 from '../../Assets/images/IHH_Turkey-min.jpg';
import projectImage2 from '../../Assets/images/website-Road2.png';
import projectImage3 from '../../Assets/images/IMG-20170718-WA0007.jpg';
import projectImage4 from '../../Assets/images/photos/DSC_6552-min.jpg';
import projectImage5 from '../../Assets/images/women-empowerment.jpg';
import projectImage6 from '../../Assets/images/out-of-school-4.jpg';
import projectImage7 from '../../../src/Assets/images/newImages/Deriswanag.jpeg';
import projectImage8 from '../../../src/Assets/images/newImages/RLAjointMonitoringField.jpeg';
import projectImage9 from '../../../src/Assets/images/newImages/SIDA2.jpeg';


const Projects = () => {
  return (
    <div className="projects-section">
      <h1 className="animated-title">Projects</h1>
      <h2>Ongoing and Completed Projects</h2>

      <div className="project-container">
            <div className="project-card">
              <img src={projectImage7} alt="Project 1" />
              <h3>Supporting Initiatives in Rehabilitation and Reintegration (SIRR)" Project</h3>
              <p>
              Supporting Initiatives in Rehabilitation and Reintegration (SIRR)" Project (Ongoing till 2026).Funded by GCERF, this project supports initiatives focused on rehabilitating and reintegrating individuals affected by violent extremism in Garissa County...
              </p>
              <a href="/sirr-project" className="view-details-button">View More Details</a>
            </div>
        
            <div className="project-card">
              <img src={projectImage8} alt="Project 1" />
              <h3>Promoting Community Resilience to Conflicts and Violent Extremism (2023-2024)</h3>
              <p>
              This initiative aims to foster community resilience to conflicts and violent extremism in Garissa County. The objective is to build stronger communities capable of withstanding ...
              </p>
              <a href="/community-resilience" className="view-details-button">View More Details</a>
            </div>
        
            <div className="project-card">
              <img src={projectImage9} alt="Project 1" />
              <h3>Resilience Learning and Adaptation Project in Garissa County.</h3>
              <p>
              The Resilience Learning and Adaptation Project, conducted in Garissa County, operates from 2022 to 2024. Funded by USAID, its primary objective is to coordinate efforts with the County Government ...
              </p>
              <a href="/resilience-adaptation-project-garissa-county" className="view-details-button">View More Details</a>
            </div>

        <div className="project-card">
          <img src={projectImage2} alt="Project 1" />
          <h3>Livestock Market Systems – Strengthening Communities Capacities for Resilience and Growth (LMS)</h3>
          <p>
            Project Title: Livestock Market Systems – Strengthening Communities Capacities for Resilience and Growth (LMS). Project Period: March 2021 - June 2022...
          </p>
          <a href="/livestock-market-systems-strengthening-communities-capacities-for-resilience-and-growth-lms" className="view-details-button">View More Details</a>
        </div>

        <div className="project-card">
          <img src={projectImage3} alt="Project 2" />
          <h3>IOM</h3>
          <p>
            IOM in Bakol Elbadre district. Renovation of borehole and water tracking.
          </p>
          <a href="/iom" className="view-details-button">View More Details</a>
        </div>

        <div className="project-card">
          <img src={projectImage1} alt="Project 3" />
          <h3>Livelihood Project</h3>
          <p>
          ROAD with support from IHH Humanitarian Relief Foundation did urgent food and medical AID to the refugee population of Daadab...
          </p>
          <a href="/livelihood" className="view-details-button">View More Details</a>
        </div>

        <div className="project-card">
          <img src={projectImage4} alt="Project 4" />
          <h3>Garissa County Action Plan (CVE)</h3>
          <p>
          This action plan has gone further to sort information on what would be the central area of Action for the...
          </p>
          <a href="/garissa-county-action-plan-cve" className="view-details-button">View More Details</a>
        </div>

        <div className="project-card">
          <img src={projectImage5} alt="Project 5" />
          <h3>Women Empowerment</h3>
          <p>
          In a world where women's empowerment is key to progress, ROAD International is dedicated to showcasing impactful projects. We believe that empowered women are change-makers, shaping communities and societies...
          </p>
          <a href="/women-empowerment" className="view-details-button">View More Details</a>
        </div>

        
        <div className="project-card">
          <img src={projectImage6} alt="Project 6" />
          <h3>The out of school children project</h3>
          <p>
          ROAD with funding from UNICEF is implementing the “Operation come to School” programme. The programme recognizes the specific needs and...
          </p>
          <a href="/the-out-of-school-children-project" className="view-details-button">View More Details</a>
        </div>
      </div>
    </div>
  );
};

export default Projects;
