// OpenTenders.jsx
import React from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';


const OpenTenders = ({ openTendersOpen, toggleOpenTenders }) => {
  return (
    <div className="section">
      <div className="section-header" onClick={toggleOpenTenders}>
        Open Tenders {openTendersOpen ? <FaMinus /> : <FaPlus />}
      </div>
      {openTendersOpen && (
        <div className="section-content">
          Currently, there are no open tenders, please check later.
        </div>
      )}
    </div>
  );
};

export default OpenTenders;
