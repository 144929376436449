// Procurement.jsx
import React, { Component } from 'react';
import OpenTenders from './OpenTenders';
import ClosedTenders from './ClosedTenders';
import './Procurement.css';

class Procurement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTendersOpen: true,
      closedTendersOpen: false,
    };
  }

  toggleOpenTenders = () => {
    this.setState({
      openTendersOpen: !this.state.openTendersOpen,
      closedTendersOpen: false,
    });
  };

  toggleClosedTenders = () => {
    this.setState({
      openTendersOpen: false,
      closedTendersOpen: !this.state.closedTendersOpen,
    });
  }

  render() {
    return (
      <div className="procurement-page1">
        <OpenTenders openTendersOpen={this.state.openTendersOpen} toggleOpenTenders={this.toggleOpenTenders} />
        <ClosedTenders closedTendersOpen={this.state.closedTendersOpen} toggleClosedTenders={this.toggleClosedTenders} />
      </div>
    );
  }
}

export default Procurement;
