import React from 'react';
import './ThematicArea.css'; 

const ThematicAreas = () => {
  return (
    <div className="thematic-areas-section">
      <h1 className="animated-title">Thematic Areas</h1>
      <div className="thematic-card">
        <h2>Food Security & Resilient Livelihoods</h2>
        <p>
        Focused on enhancing food and nutrition security, promoting climate-resilient production, and strengthening livelihoods for vulnerable communities.
        </p>
      </div>
      <div className="thematic-card">
        <h2>Environment, Natural Resources Management (NRM) & Energy Access</h2>
        <p>
          Aiming to improve environmental sustainability, responsible natural resource management, and increased access to clean and affordable energy.
        </p>
      </div>
      <div className="thematic-card">
        <h2>Water, Sanitation & Hygiene (WASH)</h2>
        <p>
        Dedicated to improving WASH services, building local capacity for sustainable planning, and increasing knowledge of hygiene in disaster-prone communities.
        </p>
      </div>
      <div className="thematic-card">
        <h2>Good Governance, Civic Engagement & Prevention of Violent Extremism</h2>
        <p>
        Emphasizing citizen engagement, improved service delivery, increased participation of youth and women in politics, and effective cooperation to counter radicalization.
        </p>
      </div>
      <div className="thematic-card">
        <h2>Education</h2>
        <p>
        Focused on enhancing education performance, improving the quality of learning institutions, and increasing access to vocational training for resilient out-of-school youth.
        </p>
      </div>
      <div className="thematic-card">
        <h2>Gender Equality & SGBV Prevention</h2>
        <p>
        We’re committed to fostering gender equality and preventing sexual and gender-based violence (SGBV). Our initiatives aim to create a safe, inclusive environment, ensuring equal opportunities for all. Through awareness and advocacy, we work towards a society free from gender-based violence, promoting a reality where everyone thrives.
        </p>
      </div>
    </div>
  );
};

export default ThematicAreas;
