// VacancyCard.jsx
import React from 'react';
import './Vacancy.css';

const VacancyCard = ({ title, subheading, description, applicationLink }) => {
  return (
    <div className="vacancy-card1">
      <h3>{title}</h3>
      <p>{subheading}</p>
      <p>{description}</p>
      <a href={applicationLink} target="_blank" rel="noopener noreferrer">Download Terms of Reference (TOR)</a>
    </div>
  );
};

export default VacancyCard;
