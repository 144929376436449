import React from 'react';
import './HelpUs.css'; // Create a CSS file for styling

// Import your money transfer merchant images
import merchantImage1 from '../../Assets/logos/visa.png';
import merchantImage2 from '../../Assets/logos/mpesa.png';
import merchantImage3 from '../../Assets/logos/paypal.png';
import merchantImage4 from '../../Assets/logos/mastercard.png';

const HelpUs = () => {
  return (
    <div className="help-us-section">
      <div className="help-us-card">
        <div className="help-us-text">
          <h1>Help us help our needy community</h1>
          <p>
            Make a donation today. Donations are acceptable via below money transfer merchants or they can be delivered at our head office.
          </p>
        </div>
        <div className="money-transfer-icons">
          <img src={merchantImage1} alt="Merchant 1" />
          <img src={merchantImage2} alt="Merchant 2" />
          <img src={merchantImage3} alt="Merchant 3" />
          <img src={merchantImage4} alt="Merchant 4" />
        </div>
      </div>
    </div>
  );
};

export default HelpUs;
