import React from 'react';
import './CoreValues.css'; // Import your CSS file for styling

const CoreValuesSection = () => {
  return (
    <div className="core-values-section">
      <h2>Our Core Values</h2>
      <div className="core-values-container">
        <div className="core-value">
          <h3>Transparency and Accountability</h3>
          <p>
            At ROAD, we uphold the pillars of transparency and accountability as fundamental to the success of our initiatives. We are committed to maintaining the highest standards of openness and responsibility in our interactions with donors, government authorities, communities, and development partners.
          </p>
        </div>
        <div className="core-value">
          <h3>Compassion with the Affected Communities</h3>
          <p>
            As a development agency, our ethos revolves around compassion for the communities and individuals we serve. We recognize and empathize with the unique challenges they face, and our approach is rooted in providing support with genuine care and kindness.
          </p>
        </div>
        <div className="core-value">
          <h3>Courage of Conviction</h3>
          <p>
            In our relentless pursuit of making a profound impact on poverty and injustices faced by communities, we embrace creativity, boldness, and innovation without fear of failure. Our courage of conviction drives us to explore unconventional solutions and approaches.
          </p>
        </div>
        <div className="core-value">
          <h3>Sense of Urgency</h3>
          <p>
            Understanding the pressing challenges faced by the communities we serve, we operate with a sense of urgency. Whether responding to emergencies or providing long-term solutions, we acknowledge the critical need for swift action to support vulnerable communities.
          </p>
        </div>
        <div className="core-value">
          <h3>Efficiency & Effectiveness</h3>
          <p>
            Acknowledging the scarcity of resources, we prioritize efficiency and effectiveness in utilizing the trust placed in us by donors and development partners. Our commitment is to ensure optimal resource utilization, minimizing wastage, and maximizing impact.
          </p>
        </div>
        <div className="core-value">
          <h3>Continuous Learning & Improvement</h3>
          <p>
            A commitment to continuous learning and improvement is ingrained in our organizational culture. We embrace regular monitoring, evaluation, and learning as integral to our approach, constantly striving for refinement and innovation in our development interventions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CoreValuesSection;
