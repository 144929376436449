import React from 'react';
import './Procurement.css';


const TenderCard = ({ title, subheading, description, torLink }) => {
  return (
    <div className="tender-card">
      <h3>{title}</h3>
      <p>{subheading}</p>
      <p>{description}</p>
      <a href={torLink} target="_blank" rel="noopener noreferrer">Download TERMS OF REFERENCE (TOR)</a>
    </div>
  );
};

export default TenderCard;
